<template>
  <div class="vat-invoice-header">
    <b-row class="mb-5">
      <b-col>
        <BDropdownCustom
          v-model="apiParams.storeIds"
          :options="filteredStore"
          :placeholder="'cửa hàng'"
          :searchable="true"
          :matchKey="['name']"
          :limit="5"
        />
      </b-col>
      <b-col>
        <div class="date-style">
          <b-input-group>
            <date-picker
              placeholder="Từ ngày cập nhật"
              class="form-control form-control-sm"
              :config="dpConfigs.date"
              v-model="apiParams.fromUpdatedDate"
              v-b-tooltip.hover
              title="Từ ngày cập nhật"
            ></date-picker>
          </b-input-group>
          <span class="ml-1 mr-1"></span>
          <b-input-group>
            <date-picker
              placeholder="Đến ngày cập nhật"
              class="form-control form-control-sm"
              :config="dpConfigs.date"
              v-model="apiParams.toUpdatedDate"
              v-b-tooltip.hover
              title="Đến ngày cập nhật"
            ></date-picker>
          </b-input-group>
        </div>
      </b-col>
      <b-col>
        <Autosuggest
          :model="searchEmployee"
          :suggestions="filteredOptionsEmployee"
          placeholder="tên nhân viên tạo hóa đơn"
          @select="onSelectedEmployee"
          @change="onInputChangeEmployee"
          suggestionName="fullName"
        >
          <template #custom="{ suggestion }">
            <div>
              <span>{{ suggestion.item.fullName }}</span>
            </div>
          </template>
        </Autosuggest>
      </b-col>
      <b-col>
        <b-input
          size="sm"
          placeholder="Ghi chú hoá đơn đỏ"
          autocomplete="off"
          v-model="apiParams.billVatNote"
        ></b-input>
      </b-col>
      <b-col>
        <b-form-select
          size="sm"
          v-model="apiParams.partnerStatus"
          :options="VAT_SYNC_STATUS"
          value-field="id"
          text-field="name"
          class="select-style"
        >
          <template v-slot:first>
            <b-form-select-option :value="null">Chọn trạng thái</b-form-select-option>
          </template>
        </b-form-select>
      </b-col>

    </b-row>
    <b-row class="mb-5">
      <b-col>
        <b-input
          size="sm"
          placeholder="Nhập ID hoá đơn bán hàng"
          autocomplete="off"
          v-model="apiParams.billNumber"
        ></b-input>
      </b-col>
      <b-col>
        <b-input
          size="sm"
          placeholder="Nhập email"
          v-model="apiParams.email"
          autocomplete="off"
        ></b-input>
      </b-col>
      <b-col>
        <b-input
          size="sm"
          placeholder="Nhập tên công ty/ cá nhân"
          autocomplete="off"
          v-model="apiParams.billVatName"
        ></b-input>
      </b-col>
      <b-col>
        <b-input
          size="sm"
          placeholder="Nhập mã số thuế"
          autocomplete="off"
          v-model="apiParams.taxCode"
        ></b-input>
      </b-col>
      <b-col>
        <b-form-select
          size="sm"
          v-model="apiParams.billVatType"
          :options="[
            { id: 1, name: 'Công ty' },
            { id: 2, name: 'Cá nhân' },
          ]"
          value-field="id"
          text-field="name"
          class="select-style"
        >
          <template v-slot:first>
            <b-form-select-option :value="null">Chọn đối tượng</b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
    </b-row>

    <b-row class="mb-5">
      <b-col cols="1">
        <b-button
          size="sm"
          variant="primary"
          style="width: 70px"
          @click="onFilter()"
        >Lọc</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { assign, cloneDeep, find, map } from 'lodash';
import ApiService from '@/core/services/api.service';
import {
  makeToastFaile,
  removeAccents,
  removeSelectAllOptions,
  selectAllOptions,
} from '@/utils/common';
import { VAT_SYNC_STATUS } from '@/utils/constants';
import timeUtils from '@/utils/date';
import apiCommonMixin from '@/view/mixins/api-common.mixins.js';

const Autosuggest = () =>
  import(
    /* webpackPrefetch: true */ '@/view/base/auto-sugguest/AutoSuggest.vue'
  );
const BDropdownCustom = () =>
  import(
    /* webpackPrefetch: true */ '@/view/base/bootstrap/BDropdownCustom.vue'
  );

export default {
  mixins: [apiCommonMixin],
  props: {
    apiParams: {
      type: Object,
      default: () => {
        return {};
      },
      required: true,
    },
  },
  components: {
    Autosuggest,
    BDropdownCustom,
  },
  data() {
    return {
      VAT_SYNC_STATUS,
      dpConfigs: null,
      filteredStore: [],
      valueStores: [],
      optionStores: [],
      searchEmployee: '',
      filteredOptionsEmployee: [],
      optionsEmployee: [],
    };
  },
  created() {
    this.dpConfigs = timeUtils.DP_CONFIG;
    this.loadOptionStores();
    this.getListEmployee();
  },
  methods: {
    getListEmployee() {
      ApiService.get('employees/getAll').then((data) => {
        const employees = data.data.data;
        this.optionsEmployee = [...employees];
        this.filteredOptionsEmployee = [...employees];
      });
    },
    onSelectedEmployee(option) {
      if (!option) {
        this.apiParams.searchEmployeeId = null;
        this.searchEmployee = '';
        return;
      }

      this.apiParams.searchEmployeeId = option.item.id;
      this.searchEmployee = option.item.fullName;
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
        this.apiParams.searchEmployeeId = text;
      }
      this.searchEmployee = text;

      const filteredData = [...this.optionsEmployee].filter((item) => {
        return item.fullName.toLowerCase().indexOf(text.toLowerCase()) > -1;
      });

      this.filteredOptionsEmployee = [...filteredData];
    },
    async loadOptionStores() {
      try {
        const response = await this.fetchStores();
        const stores = response.data;

        this.optionStores = [...stores];
        this.filteredStore = cloneDeep(this.optionStores);
      } catch (error) {
        if (error.response) {
          makeToastFaile(
            error.response.data
              ? error.response.data.message
              : 'Có vấn đề xảy ra hãy thử lại',
          );
        } else {
          makeToastFaile(error.message || 'Có vấn đề xảy ra hãy thử lại');
        }
      }
    },
    onFilter() {
      this.$emit('getParamFilters');
    },
    onInputed(textInput = '', type) {
      switch (type) {
        case 'Store': {
          this.searchStore(textInput);
          break;
        }

        default:
          break;
      }
    },
    onSelected(option, type) {
      switch (type) {
        case 'Store': {
          const { items, selectItems } = selectAllOptions(
            this.optionStores,
            this.apiParams.storeIds,
            option,
          );
          this.filteredStore = items;
          this.apiParams.storeIds = selectItems;
          break;
        }

        default:
          break;
      }
    },
    onRemoved(option, type) {
      switch (type) {
        case 'Store': {
          const { items, selectItems } = removeSelectAllOptions(
            this.optionStores,
            this.apiParams.storeIds,
            option,
          );

          this.filteredStore = items;
          this.apiParams.storeIds = selectItems;
          break;
        }
        default:
          break;
      }
    },
    searchStore(textInput) {
      let options = cloneDeep(this.optionStores);
      if (!textInput || !textInput.trim().length) {
        this.filteredStore = map(options, (obj) => {
          return assign(obj, find(this.filteredStore, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filteredStore, { id: obj.id }));
      });

      this.filteredStore = this.filterOptionsBy(options, textInput, 'name', 10);
    },
    filterOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.vat-invoice-header {
  .multiselect__checkbox-name {
    display: block;
    overflow: hidden;
  }

  .multiselect__checkbox {
    vertical-align: middle;
    float: right;
  }

  .multiselect__tags {
    height: calc(1.35em + 1.1rem + 2px);
    display: block;
    border-radius: 0.28rem;
    border: 1px solid #ced4da;
    font-size: 0.925rem;
    min-height: initial;
    padding-top: 0.3rem;
  }

  .multiselect__option {
    padding: 5px;
    padding-top: 8px;
    min-height: 30px;
  }

  .multiselect__select {
    width: 3rem;
    height: 2.5rem;
    top: 0.1rem;
  }

  .multiselect__placeholder {
    color: #495057;
    padding-left: 0.2rem;
  }

  .multiselect__element {
    font-size: 0.925rem;
  }

  .multiselect__input {
    padding-top: 0.2rem;
    font-size: 0.925rem;
  }

  .multiselect__single {
    font-size: 0.925rem;
    line-height: 1.9;
    padding-left: 0.2rem;
    display: inline-block;
    max-width: 100%;
  }
}
</style>
